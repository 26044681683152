<template>
	<!-- begin:: Header -->
	<div id="kt_header" ref="kt_header" class="header" v-bind:class="headerClasses">
		<div class="container-fluid d-flex align-items-center justify-content-between">
			<!-- begin:: Header Menu -->
			<div class="header-menu-wrapper header-menu-wrapper-left" ref="kt_header_menu_wrapper">
				<div
					v-if="headerMenuEnabled"
					id="kt_header_menu"
					ref="kt_header_menu"
					class="header-menu header-menu-mobile"
					v-bind:class="headerMenuClasses"
				>
					<v-app>
						<template v-if="is_dashboard()">
							<img :src="site_logo()" alt="Logo" style="width: 200px" />
						</template>
						<template v-else>
							<v-text-field
								ref="search"
								v-model.trim="search_item_name"
								:background-color="!is_focused ? 'grey lighten-3' : undefined"
								:flat="!is_focused && !is_searching"
								:placeholder="placeholder"
								autocomplete="off"
								class="mx-2 mx-md-4 rounded-lg"
								dense
								hide-details
								solo
								clearable
								color="blue darken-4"
								v-on:click:clear="on_esc()"
								:append-icon="show_setting ? 'mdi-cog' : ''"
								v-on:click:append="open_detail = !open_detail"
								style="max-width: 450px; min-width: 400px"
								v-on:blur="on_blur()"
								v-on:keypress="keypress_search()"
								v-on:focus="is_focused = true"
								v-on:keydown.esc="on_esc()"
								v-on:keydown.enter="do_search()"
								v-on:keydown.tab="do_search()"
							>
								<template #prepend-inner>
									<v-icon :color="!is_focused ? 'grey' : undefined" class="ml-1 mr-2"> mdi-magnify </v-icon>
								</template>
							</v-text-field>
							<v-expand-transition v-if="show_setting">
								<div v-if="open_detail" class="search-filters bg-white">
									<div v-if="is_activity_log" class="px-4 py-4">
										<table width="100%" style="table-layout: fixed">
											<tr>
												<td width="30%" valign="middle">
													<label class="my-0 py-0" for="search-owner">Owner</label>
												</td>
												<td width="70%" valign="middle">
													<v-select
														v-model="search_owner"
														cache-items
														:items="owner_list"
														hide-details
														id="search-owner"
														:menu-props="{ contentClass: 'search-type-auto-complete' }"
													>
														<template v-slot:selection="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
														<template v-slot:item="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
													</v-select>
												</td>
											</tr>
											<tr v-if="search_owner == 'specific-person'">
												<td valign="middle">
													<label class="my-0 py-0" for="search-owned-by">Owned By</label>
												</td>
												<td valign="middle">
													<v-text-field
														v-model.trim="search_owned_by"
														hide-details
														id="search-owned-by"
														placeholder="Enter a name or email address..."
													></v-text-field>
												</td>
											</tr>
											<tr>
												<td valign="middle">
													<label class="my-0 py-0" for="search-item-name">Description</label>
												</td>
												<td valign="middle">
													<v-text-field
														v-model.trim="search_item_name"
														hide-details
														id="search-item-name"
														placeholder="Enter a term that matches part of the file name"
													></v-text-field>
												</td>
											</tr>
											<tr>
												<td width="30%" valign="middle">
													<label class="my-0 py-0" for="search-date-modified">Date Modified</label>
												</td>
												<td width="70%" valign="middle">
													<v-select
														v-model="search_date_modified"
														cache-items
														:items="date_list"
														hide-details
														id="search-date-modified"
														:menu-props="{ contentClass: 'search-type-auto-complete' }"
													>
														<template v-slot:selection="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
														<template v-slot:item="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
													</v-select>
													<v-layout v-if="search_date_modified == 'custom'">
														<v-flex md6>
															<DatePicker v-model="search_start_date" placeholder="Start date"></DatePicker>
														</v-flex>
														<v-flex md6>
															<DatePicker
																v-model="search_end_date"
																placeholder="End date"
																:min-date="search_start_date"
															></DatePicker>
														</v-flex>
													</v-layout>
												</td>
											</tr>
											<tr>
												<td width="100%" valign="middle" colspan="2" height="30px"></td>
											</tr>
											<tr>
												<td width="30%" valign="middle"></td>
												<td width="70%" valign="middle" align="right">
													<v-btn v-on:click="open_detail = false" depressed tile>CLOSE</v-btn>
													<v-btn v-on:click="reset_search()" class="mx-3" depressed tile>RESET</v-btn>
													<v-btn v-on:click="do_search()" class="blue darken-4 white--text" depressed tile
														>SEARCH</v-btn
													>
												</td>
											</tr>
										</table>
									</div>
									<div v-else class="px-4 py-4">
										<table width="100%" style="table-layout: fixed">
											<tr>
												<td width="30%" valign="middle">
													<label class="my-0 py-0" for="search-type">Type</label>
												</td>
												<td width="70%" valign="middle">
													<v-select
														v-model="search_type"
														cache-items
														:items="type_list"
														hide-details
														id="search-type"
														:menu-props="{ contentClass: 'search-type-auto-complete' }"
													>
														<template v-slot:selection="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
														<template v-slot:item="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
													</v-select>
												</td>
											</tr>
											<tr>
												<td valign="middle">
													<label class="my-0 py-0" for="search-tag">Tag</label>
												</td>
												<td valign="middle">
													<v-select
														v-model="search_tag"
														cache-items
														:items="tag_list"
														hide-details
														id="search-tag"
														:menu-props="{ contentClass: 'search-type-auto-complete' }"
													>
														<template v-slot:selection="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
														<template v-slot:item="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
													</v-select>
												</td>
											</tr>
											<tr>
												<td valign="middle">
													<label class="my-0 py-0" for="search-owner">Owner</label>
												</td>
												<td valign="middle">
													<v-select
														v-model="search_owner"
														cache-items
														:items="owner_list"
														hide-details
														id="search-owner"
														:menu-props="{ contentClass: 'search-type-auto-complete' }"
													>
														<template v-slot:selection="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
														<template v-slot:item="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
													</v-select>
												</td>
											</tr>
											<tr v-if="search_owner == 'specific-person'">
												<td valign="middle">
													<label class="my-0 py-0" for="search-owned-by">Owned By</label>
												</td>
												<td valign="middle">
													<v-text-field
														v-model.trim="search_owned_by"
														hide-details
														id="search-owned-by"
														placeholder="Enter a name or email address..."
													></v-text-field>
												</td>
											</tr>
											<tr>
												<td valign="middle">
													<label class="my-0 py-0" for="search-includes-the-words">Includes the words</label>
												</td>
												<td valign="middle">
													<v-text-field
														v-model.trim="search_include_the_word"
														hide-details
														id="search-includes-the-words"
														placeholder="Enter words found in the file"
													></v-text-field>
												</td>
											</tr>
											<tr>
												<td valign="middle">
													<label class="my-0 py-0" for="search-item-name">Item Name</label>
												</td>
												<td valign="middle">
													<v-text-field
														v-model.trim="search_item_name"
														hide-details
														id="search-item-name"
														placeholder="Enter a term that matches part of the file name"
													></v-text-field>
												</td>
											</tr>
											<tr>
												<td valign="top">
													<label class="my-0 mt-5 py-0" for="search-location">Location</label>
												</td>
												<td valign="top">
													<v-btn v-if="false" depressed tile outlined class="mt-3">ANYWHERE</v-btn>
													<table width="100%">
														<tr>
															<td>
																<v-checkbox
																	v-model="search_location_bin"
																	label="In Bin"
																	:true-value="1"
																	:false-value="0"
																	hide-details
																></v-checkbox>
															</td>
															<td>
																<v-checkbox
																	v-model="search_location_starred"
																	label="Starred"
																	:true-value="1"
																	:false-value="0"
																	hide-details
																></v-checkbox>
															</td>
															<td>
																<v-checkbox
																	v-model="search_location_locked"
																	label="Locked"
																	:true-value="1"
																	:false-value="0"
																	hide-details
																></v-checkbox>
															</td>
														</tr>
													</table>
												</td>
											</tr>
											<tr>
												<td valign="middle">
													<label class="my-0 py-0" for="search-date-modified">Date Modified</label>
												</td>
												<td valign="middle">
													<v-select
														v-model="search_date_modified"
														cache-items
														:items="date_list"
														hide-details
														id="search-date-modified"
														:menu-props="{ contentClass: 'search-type-auto-complete' }"
													>
														<template v-slot:selection="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
														<template v-slot:item="{ item }">
															<v-list-item-avatar
																v-if="item.icon"
																height="25px"
																width="40px"
																min-width="40px"
																class="my-0 mr-1"
															>
																<v-icon :color="item.color">{{ item.icon }}</v-icon>
															</v-list-item-avatar>
															<v-list-item-content>
																<v-list-item-title v-html="item.text"></v-list-item-title>
															</v-list-item-content>
														</template>
													</v-select>
													<v-layout v-if="search_date_modified == 'custom'">
														<v-flex md6>
															<DatePicker v-model="search_start_date" placeholder="Start date"></DatePicker>
														</v-flex>
														<v-flex md6>
															<DatePicker
																v-model="search_end_date"
																placeholder="End date"
																:min-date="search_start_date"
															></DatePicker>
														</v-flex>
													</v-layout>
												</td>
											</tr>
											<tr>
												<td valign="middle">
													<label class="my-0 py-0" for="search-shared-to">Shared to</label>
												</td>
												<td valign="middle">
													<v-text-field
														v-model.trim="search_shared_to"
														hide-details
														id="search-shared-to"
														placeholder="Enter a name or email address..."
													></v-text-field>
												</td>
											</tr>
											<tr>
												<td valign="middle" colspan="2" height="30px"></td>
											</tr>
											<tr>
												<td valign="middle"></td>
												<td valign="middle" align="right">
													<v-btn v-on:click="open_detail = false" depressed tile>CLOSE</v-btn>
													<v-btn v-on:click="reset_search()" class="mx-3" depressed tile>RESET</v-btn>
													<v-btn v-on:click="do_search()" class="blue darken-4 white--text" depressed tile
														>SEARCH</v-btn
													>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</v-expand-transition>
						</template>
					</v-app>
				</div>
			</div>
			<!-- end:: Header Menu -->
			<KTTopbar></KTTopbar>
		</div>
	</div>
	<!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import DatePicker from "@/view/components/DatePicker";
import Moment from "moment-timezone";
import ObjectPath from "object-path";
import { toSafeInteger } from "lodash";
import { EventBus } from "@/core/event-bus/event.bus";
import { QUERY } from "@/core/services/store/request.module";

Moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "KTHeader",
	data() {
		return {
			is_activity_log: false,
			show_setting: false,
			open_detail: false,
			is_searching: false,
			is_focused: false,
			search_type: "any",
			search_tag: "any",
			search_owner: "any",
			search_date_modified: "any-time",
			search_owned_by: null,
			search_shared_to: null,
			search_item_name: null,
			search_include_the_word: null,
			search_start_date: null,
			search_end_date: null,
			search_location_bin: false,
			search_location_starred: false,
			search_location_locked: false,
			type_list: [
				{
					text: "Any",
					value: "any",
					icon: null,
					color: null,
				},
				{
					text: "Photos & Images",
					value: "photos-images",
					icon: "mdi-image",
					color: "red darken-2",
				},
				{
					text: "PDFs",
					value: "pdfs",
					icon: "mdi-file-pdf-box",
					color: "red darken-1",
				},
				{
					text: "Documents",
					value: "documents",
					icon: "mdi-file-document",
					color: "blue darken-1",
				},
				{
					text: "Spreadsheets",
					value: "spreadsheets",
					icon: "mdi-google-spreadsheet",
					color: "green darken-2",
				},
				{
					text: "Presentations",
					value: "presentations",
					icon: "mdi-file-presentation-box",
					color: "yellow darken-3",
				},
				{
					text: "Audio",
					value: "audio",
					icon: "mdi-headphones",
					color: "red darken-2",
				},
				{
					text: "Video",
					value: "video",
					icon: "mdi-movie",
					color: "red darken-2",
				},
				{
					text: "Archives (zip)",
					value: "archives-zip",
					icon: "mdi-folder-zip",
					color: "grey darken-3",
				},
				{
					text: "Folders",
					value: "folders",
					icon: "mdi-folder",
					color: "grey darken-3",
				},
			],
			owner_list: [
				{
					text: "Any",
					value: "any",
					icon: null,
					color: null,
				},
				{
					text: "Owned by me",
					value: "owned-by-me",
					icon: null,
					color: null,
				},
				{
					text: "Not owned by me",
					value: "not-owned-by-me",
					icon: null,
					color: null,
				},
				{
					text: "Specific person...",
					value: "specific-person",
					icon: null,
					color: null,
				},
			],
			date_list: [
				{
					text: "Any time",
					value: "any-time",
					icon: null,
					color: null,
				},
				{
					text: "Today",
					value: "today",
					icon: null,
					color: null,
				},
				{
					text: "Yesterday",
					value: "yesterday",
					icon: null,
					color: null,
				},
				{
					text: "Last 7 days",
					value: "last-seven-days",
					icon: null,
					color: null,
				},
				{
					text: "Last 30 days",
					value: "last-thirty-days",
					icon: null,
					color: null,
				},
				{
					text: "Last 90 days",
					value: "last-ninty-days",
					icon: null,
					color: null,
				},
				{
					text: "Custom...",
					value: "custom",
					icon: null,
					color: null,
				},
			],
			tag_list: [
				{
					text: "Any",
					value: "any",
					icon: null,
					color: null,
				},
			],
			timeout: null,
			timeout_limit: 500,
		};
	},
	watch: {
		$route() {
			this.init_filters();
		},
		search_item_name(param) {
			if(!param){
				this.keypress_search();
			}
		}
	},
	components: {
		DatePicker,
		KTTopbar,
		// KTMenu,
	},
	methods: {
		is_dashboard() {
			const route_name = ObjectPath.get(this.$route, "name", null);
			if (route_name == "dashboard") {
				return true;
			}
			return false;
		},
		site_logo() {
			return `${process.env.BASE_URL}media/logos/logo-genic-text.png`;
		},
		init_filters() {
			this.search_type = ObjectPath.get(this.$route, "query.search-type", "any");
			this.search_owner = ObjectPath.get(this.$route, "query.search-owner", "any");
			this.search_tag = ObjectPath.get(this.$route, "query.search-tag", "any");
			this.search_date_modified = ObjectPath.get(
				this.$route,
				"query.search-date-modified",
				"any-time"
			);
			this.search_owned_by = ObjectPath.get(this.$route, "query.search-owned-by", null);
			this.search_shared_to = ObjectPath.get(this.$route, "query.search-shared-to", null);
			this.search_item_name = ObjectPath.get(this.$route, "query.search-item-name", null);
			this.search_include_the_word = ObjectPath.get(
				this.$route,
				"query.search-include-the-word",
				null
			);
			this.search_start_date = ObjectPath.get(this.$route, "query.search-start-date", null);
			this.search_end_date = ObjectPath.get(this.$route, "query.search-end-date", null);
			const search_location_bin = ObjectPath.get(this.$route, "query.search-location-bin", 0);
			this.search_location_bin = toSafeInteger(search_location_bin);
			const search_location_starred = ObjectPath.get(this.$route, "query.search-location-starred", 0);
			this.search_location_starred = toSafeInteger(search_location_starred);
			const search_location_locked = ObjectPath.get(this.$route, "query.search-location-locked", 0);
			this.search_location_locked = toSafeInteger(search_location_locked);
		},
		reset_search() {
			this.search_type = "any";
			this.search_owner = "any";
			this.search_tag = "any";
			this.search_date_modified = "any-time";
			this.search_owned_by = null;
			this.search_shared_to = null;
			this.search_item_name = null;
			this.search_include_the_word = null;
			this.search_start_date = null;
			this.search_end_date = null;
			this.search_location_bin = false;
			this.search_location_starred = false;
			this.search_location_locked = false;
			this.$nextTick(() => {
				this.do_search();
			});
		},
		keypress_search() {
			const _this = this;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				_this.do_search();
			}, _this.timeout_limit);
		},
		do_search() {
			const query = {
				"search-type": this.search_type || undefined,
				"search-owner": this.search_owner || undefined,
				"search-tag": this.search_tag || undefined,
				"search-date-modified": this.search_date_modified || undefined,
				"search-owned-by": this.search_owned_by || undefined,
				"search-shared-to": this.search_shared_to || undefined,
				"search-item-name": this.search_item_name || undefined,
				"search-include-the-word": this.search_include_the_word || undefined,
				"search-start-date": this.search_start_date || undefined,
				"search-end-date": this.search_end_date || undefined,
				"search-location-bin": this.search_location_bin || undefined,
				"search-location-starred": this.search_location_starred || undefined,
				"search-location-locked": this.search_location_locked || undefined,
				t: Moment().unix(),
			};

			this.is_searching = false;
			this.is_focused = false;

			this.$router
				.push({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...query,
					},
				})
				.then(() => {
					this.open_detail = false;
				})
				.catch(() => {
					//
				});
		},
		on_blur() {
			this.is_searching = false;
			this.is_focused = false;
		},
		on_esc() {
			this.is_searching = false;
			this.is_focused = false;
			this.search_item_name = null;
			this.$nextTick(() => {
				this.do_search();
			});
		},
		get_tags() {
			this.$store
				.dispatch(QUERY, { url: `account/tag?t=${new Date().getTime()}` })
				.then((response) => {
					this.tag_list = response;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	mounted() {
		this.get_tags();

		// Init Desktop & Mobile Headers
		KTLayoutHeader.init("kt_header", "kt_header_mobile");

		// Init Header Menu
		KTLayoutHeaderMenu.init(this.$refs["kt_header_menu"], this.$refs["kt_header_menu_wrapper"]);

		const headerRef = this.$refs["kt_header"];

		headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
			item.addEventListener("click", () => {
				KTLayoutHeaderMenu.getOffcanvas().hide();
			});
		});

		EventBus.$on("show-search-setting", (param) => {
			this.show_setting = param;
			this.open_detail = false;
		});

		EventBus.$on("is-activity-log", (param) => {
			this.is_activity_log = param;
		});
	},
	beforeDestroy() {
		EventBus.$off("show-search-setting");
		EventBus.$off("is-activity-log");
	},
	beforeMount() {
		this.init_filters();
	},
	computed: {
		...mapGetters(["layoutConfig", "getClasses"]),

		/**
		 * Check if the header menu is enabled
		 * @returns {boolean}
		 */
		headerMenuEnabled() {
			return !!this.layoutConfig("header.menu.self.display");
		},

		/**
		 * Get extra classes for header based on the options
		 * @returns {null|*}
		 */
		headerClasses() {
			const classes = this.getClasses("header");
			if (typeof classes !== "undefined") {
				return classes.join(" ");
			}
			return null;
		},

		/**
		 * Get extra classes for header menu based on the options
		 * @returns {null|*}
		 */
		headerMenuClasses() {
			const classes = this.getClasses("header_menu");
			if (typeof classes !== "undefined") {
				return classes.join(" ");
			}
			return null;
		},
		placeholder() {
			return this.is_focused ? "Start Typing then press enter" : "Search ...";
		},
	},
};
</script>
