<template>
	<div class="topbar-item">
		<div
			class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 mr-4"
			style="height: calc(1.5em + 1.65rem)"
		>
			<span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
				License No.
			</span>
			<span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline">
				{{ $licenseCode }}
			</span>
		</div>

		<div
			class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
			style="height: calc(1.5em + 1.65rem)"
			id="kt_quick_user_toggle"
		>
			<span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"> Hi, </span>
			<span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
				{{ currentUserPersonalInfo.first_name }}
			</span>
			<span class="symbol symbol-30 symbol-light-success">
				<span class="symbol-label font-size-h5 font-weight-bold">
					{{ currentUserPersonalInfo.first_name.charAt(0).toUpperCase() }}
				</span>
			</span>
		</div>

		<div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
			<!--begin::Header-->
			<div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
				<h3 class="font-weight-bold m-0">User Profile</h3>
				<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
					<i class="ki ki-close icon-xs text-muted"></i>
				</a>
			</div>
			<!--end::Header-->

			<!--begin::Content-->
			<perfect-scrollbar
				class="offcanvas-content pr-5 mr-n5 scroll"
				style="max-height: 90vh; position: relative"
			>
				<!--begin::Header-->
				<div class="d-flex align-items-center mt-5">
					<div class="symbol symbol-100 mr-5">
						<img class="symbol-label" :src="currentUserPersonalInfo.profile" alt="" />
						<i class="symbol-badge bg-success"></i>
					</div>
					<div class="d-flex flex-column">
						<span class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
							{{ currentUserPersonalInfo.display_name }}
						</span>
						<div class="navi mt-2">
							<a href="#" class="navi-item">
								<span class="navi-link p-0">
									<span class="navi-icon mr-1">
										<span class="svg-icon svg-icon-lg svg-icon-primary">
											<!--begin::Svg Icon-->
											<inline-svg src="media/svg/icons/Communication/Call.svg" />
											<!--end::Svg Icon-->
										</span>
									</span>
									<span class="navi-text text-muted text-hover-primary">
										{{ currentUserPersonalInfo.phone_number }}
									</span>
								</span>
							</a>
						</div>
						<div class="navi mt-2">
							<a href="#" class="navi-item">
								<span class="navi-link p-0 pb-2">
									<span class="navi-icon mr-1">
										<span class="svg-icon svg-icon-lg svg-icon-primary">
											<!--begin::Svg Icon-->
											<inline-svg src="media/svg/icons/Communication/Mail-notification.svg" />
											<!--end::Svg Icon-->
										</span>
									</span>
									<span class="navi-text text-muted text-hover-primary">
										{{ currentUserPersonalInfo.email }}
									</span>
								</span>
							</a>
						</div>
						<button class="btn btn-light-primary btn-bold" @click="onLogout">Sign out</button>
					</div>
				</div>
				<div class="mt-8 d-flex align-items-center border border-dashed border-gray-300 rounded p-5">
					<div class="d-flex flex-aligns-center pe-10 pe-lg-20">
						<img alt="" class="w-50px me-3" :src="$assetURL('media/mime/pdf.svg')" />
						<div class="ml-4 ms-1 fw-semobold">
							<a
								target="_blank"
								:href="$assetURL('files/user-manual-guide-for-dms.pdf')"
								class="fs-6 text-hover-primary fw-bold font-weight-bold"
							>
								User Manual Guide for DMS
							</a>
							<div class="text-muted">5.33 MB</div>
						</div>
					</div>
				</div>
			</perfect-scrollbar>
			<!--end::Content-->
		</div>
	</div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
	overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
	name: "KTQuickUser",
	data() {
		return {};
	},
	mounted() {
		// Init Quick User Panel
		KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
	},
	methods: {
		onLogout() {
			this.$store
				.dispatch(LOGOUT)
				.then(() => {
					this.$router.push({ name: "login" });
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		closeOffcanvas() {
			new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
		},
	},
	computed: {
		...mapGetters(["currentUserPersonalInfo"]),
	},
};
</script>
